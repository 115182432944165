
.fullbody {
    display: flex;
    flex-flow: column;
}

.header {
    flex: 0 1 auto; 
}

.bar {
     display: flex;
     width: 100%;
     flex: 0 1 auto;
     padding-bottom: 10px;
}

.section {
    height: 100%;
    width: 100%
}

.section .title {
    height: 20%;
    font-size: 8px;
    color: white;
}

.section .subTitle {
    height: 20%;
    font-size: 8px;
    color: white;
}

.colorSection {
    height: 50%;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.column-row {
    min-height: 26%;
    height: 100%;

}

.row {
    padding-bottom: 10px;
}

.icarian {
    background: rgb(51,52,109);
}

.growth {
    background: rgb(172,205,36) 
}

.flatliner {
    background: rgb(192,203,38)
}

.correction {
    background: rgb(204,56,38)
}

.abysmal {
    background: rgb(109,59,50)
}

.stocklayout {
    flex: 1 1 auto;
}