body {
  margin: 0;
  background-color: #0d0d0d !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stock-search-input {
  font-weight: 900 !important;
}

.stock-search-symbol {
  font-weight: 900 !important;
}

.stock-search-name {
  font-weight: 900 !important;
}

.nav-header {
  --bs-bg-opacity: 1;
  background-color: #010101;
}

figcaption {
  color: white !important;
  font-size: 0.75rem !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #030303 !important;
} 

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  background-color: #030303 !important;
} 

.fullStack {
  height: 90%;
  margin-top: 10%;
}

.title-text {
  color: #fefec5 !important
}

.description-item {
  background-color: transparent !important;
  color: white !important;
  border-color: transparent !important;
}

.description-card {
  background-color: transparent !important;
  color: white !important;
}

.card-link {
  color: #fefec5;
}

.offcanvas { 
  background-color: #010101 !important;
}

.offcanvas-title {
  color: #fefec5 !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}


.navbar-toggler-icon {
  background: url('hamburger-icon-30.png') no-repeat !important;
}

.navbar-toggler {
  margin-top: -30px;
}